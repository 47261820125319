
import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaFacebook, FaYoutube, FaInstagram, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import defaultBanner from '../images/IMG 63.png';
import './Navbar.css'; 
const porturl = process.env.REACT_APP_PORT_URL;
const baseurl = process.env.REACT_APP_BASE_URL;

const MyNavbar = ({ setting, isOpen, toggleNavbar, scrollToSection }) => (
  <Navbar expand="lg" className="portfolio-navbar navbar-dark">
    <Navbar.Brand href="/" className="brand-name p-3">Anurag Soni</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => toggleNavbar()}>
      {isOpen ? <FaTimes className="navbar-icon" /> : <FaBars className="navbar-icon" />}
    </Navbar.Toggle>
    <Navbar.Collapse id="basic-navbar-nav" className={`${isOpen ? "show" : ""} justify-content-end`}>
      <Nav className="ml-auto text-uppercase text-white">
        <Nav.Link as={Link} to="/">Home</Nav.Link>
        <Nav.Link onClick={() => { scrollToSection('keynotesSection'); }}>KeyNote</Nav.Link>
        <Nav.Link onClick={() => { scrollToSection('gallerySection'); }}>Gallery</Nav.Link>
        <Nav.Link onClick={() => { scrollToSection('blogSection'); }}>Motivation</Nav.Link>
        <Nav.Link onClick={() => { scrollToSection('contactSection'); }}>Contact</Nav.Link>
      </Nav>
      <div className="social-icons p-3">
        <Nav.Link href={setting[0]?.facebook} target="_blank" className='fbicon'><FaFacebook /></Nav.Link>
        {/* <Nav.Link href={setting[0]?.twitter} target="_blank" className="twitter-icon"><FaSquareXTwitter /></Nav.Link> */}
        <Nav.Link href={setting[0]?.instagram} target="_blank" className="instagram-icon"><FaInstagram /></Nav.Link>
      </div>
    </Navbar.Collapse>
  </Navbar>
);

function PortfolioNavbar() {
  const [bannerImage, setBannerImage] = useState('');
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = (state) => {
    setIsOpen(state !== undefined ? state : !isOpen);
  };

  useEffect(() => {
    // Fetch banner data from API
  
    axios.get(`${baseurl}/Baanner`)
      .then(response => {
        console.log(`${baseurl}/Baanner`);
        if (response.data) {
          let imageUrl = `${porturl}${response.data[0].image}`;
          imageUrl = imageUrl.replace(/\\/g, '/');

          // Preload image
          const img = new Image();
          img.src = imageUrl;

          img.onload = () => {
            setBannerImage(imageUrl); 
            setLoading(false); 
          };
          
          setBannerData(response.data); 
        }
      })
      .catch(error => {
        console.error('Error fetching banner:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios.get(`${baseurl}/settings`)
      .then((response) => {
        if (response.data) {
          setSetting(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching footer settings:', error);
      });
  }, []);

  const scrollToSection = (sectionId, delay = 400) => {
    setTimeout(() => {
      document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    }, delay);
  };

  return (
    <div className='imagebg' style={{ backgroundImage: loading ? `url(${defaultBanner})` : `url(${bannerImage})` }}>
      <MyNavbar setting={setting} isOpen={isOpen} toggleNavbar={toggleNavbar} scrollToSection={scrollToSection} />
      <div className="header-content">
        <div className="text-box text-capitalize brand-name text-center">
          {bannerData.length > 0 && (
            <>
              <h4 className='bannerTextdesc'>{bannerData[0]?.description}</h4>
              {/* <h3 style={{ color: '#ffb319' }}>- {bannerData[0]?.name}</h3> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PortfolioNavbar;
